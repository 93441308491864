import React from 'react';
import { SortBy } from './SortBy';
import { DATA_HOOKS } from './dataHooks';
import { st, classes } from './SearchSortingBox.st.css';
import { AppRootActions } from '../App/types';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { compose } from '../../../../common/utils/compose';
import { withTPAConfig } from '../../../../common/components/withTPAConfig';
import { Block } from '../../../../common/components/Block';
import { Search } from '../../../../common/components/Search/Search';

export interface SearchSortingBoxProps {
  onSearch: AppRootActions['searchGroups'];
  onSort: AppRootActions['sortGroups'];
  initialSortSelectedId: string;
}

export const SearchSortingBoxComponent: React.FC<
  SearchSortingBoxProps &
    WithTranslation & {
      mobile: boolean;
    }
> = ({ mobile, t, onSort, initialSortSelectedId, onSearch }) => (
  <div
    className={st(classes.root, { mobile })}
    data-hook={DATA_HOOKS.searchSortingBox}
  >
    <Block
      end={
        <Search
          withCloseButton={true}
          placeholder={t('groups-web.search.placeholder')}
          onChange={onSearch}
          className={classes.search}
        />
      }
      stylesOverride={{
        gridTemplateColumns: 'minmax(0, max-content)',
      }}
    >
      <div className={classes.overflowX}>
        <SortBy
          onChange={onSort}
          initialSelectedId={initialSortSelectedId}
          className={classes.sortBy}
          mobile={mobile}
        />
      </div>
    </Block>
  </div>
);

const enhance = compose(withTranslation(), withTPAConfig);

export const SearchSortingBox = enhance(
  SearchSortingBoxComponent,
) as React.ComponentType<SearchSortingBoxProps>;
