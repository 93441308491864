import React from 'react';

import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';

import { AppRootActions } from '../App/types';

import { PendingGroupMeta } from './PendingGroupMeta';
import { PendingGroupActions } from './PendingGroupActions';
import { PendingGroupsMobile } from './PendingGroupsMobile';

import { classes } from './PendingGroupList.st.css';
import { classes as cardClasses } from '../../../../common/components/GroupCard/layout/GroupCard.st.css';
import classnames from 'classnames';
import { WithGroupsProps } from '../../contexts/withGroupsProps';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { getSettingsKeyFor } from '../../../../common/utils/utils';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { settingsParams } from '../../../../common/settings/groups-list/settings';
import { ExpandableContainer } from '../../../../common/components/Container/ExpandableContainer';
import { GroupListCard } from '../../../../common/components/GroupCard/layout/GroupListCard';

const { PENDING } = ApiTypes.v1.GroupApprovalStatus;

export interface PendingGroupListProps extends Partial<WithGroupsProps> {
  actions: AppRootActions;
  groups: ApiTypes.v1.GroupResponse[];
  couldUserResolvePendingGroups: boolean;
  mobile: boolean;
  groupSectionUrl: string;
}

function pendingGroupsFilter(group: ApiTypes.v1.GroupResponse) {
  return group.approvalStatus === PENDING;
}

export function PendingGroupListComponent(props: PendingGroupListProps) {
  const { couldUserResolvePendingGroups, groups, mobile, groupUrls } = props;
  const settings = useSettings();
  const { t } = useTranslation();
  const { isViewer } = useEnvironment();
  const param = getSettingsKeyFor('imageRatio', false);
  const imageRatio = settings.get(settingsParams[param]);
  const pendingGroups = groups.filter(pendingGroupsFilter);
  if (mobile) {
    return <PendingGroupsMobile total={pendingGroups.length} />;
  }

  const title = couldUserResolvePendingGroups
    ? 'groups-web.group-list.pending-groups.title'
    : 'groups-web.group-list.pending-groups.creator-title';

  return (
    <ExpandableContainer
      title={
        <Text typography={TYPOGRAPHY.runningText} className={classes.title}>
          {t(title)}
          <span className={classes.badge}>{pendingGroups.length}</span>
        </Text>
      }
      className={classes.root}
    >
      {pendingGroups.map((group) => {
        const groupUrl = isViewer ? groupUrls![group.groupId!] : undefined;
        return (
          <GroupListCard
            key={group.groupId}
            hasSpacing={false}
            mobile={false}
            group={group}
            imageRatio={imageRatio}
            cardMetaComponent={PendingGroupMeta}
            cardActionsComponent={PendingGroupActions}
            groupUrl={groupUrl!}
            className={classnames(classes.groupCard, cardClasses.root)}
          />
        );
      })}
    </ExpandableContainer>
  );
}

export const PendingGroupList =
  PendingGroupListComponent as React.ComponentType<PendingGroupListProps>;
