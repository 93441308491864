import React, { FC } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { withWidgetWCS } from '@wix/yoshi-flow-editor/wcs/wrappers';
import AppRoot from './App/AppRoot';
import { GroupPageWithStyles } from './App/types';
import { settingsParams } from '../../../common/settings/groups-list/settings';
import { stylesParams } from '../../../common/settings/groups-list/styles';

const Widget: FC<WidgetProps<GroupPageWithStyles>> = (props) => {
  return (
    <div data-hook="Groups-wrapper">
      <AppRoot {...props} />
    </div>
  );
};

export default Widget;
export const GroupsWCS = withWidgetWCS(Widget, settingsParams, stylesParams);
