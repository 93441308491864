import React from 'react';
import { canResolvePendingGroups, memberWrapper } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import { classes } from './PendingGroupMeta.st.css';
import {
  withCurrentUser,
  WithCurrentUserProps,
} from '../../contexts/withCurrentUser';
import { DATA_HOOKS } from './dataHooks';
import { withTranslation, WithTranslation } from '@wix/yoshi-flow-editor';
import { GroupTitleLink } from '../../../../common/components/GroupCard/Bricks';
import { GroupPrivacyLevel } from '../../../../common/components/GroupCard/meta/GroupPrivacy';
import { compose } from '../../../../common/utils/compose';

interface PendingGroupMetaProps {
  group: ApiTypes.v1.GroupResponse;
  groupUrl: string;
}

export class PendingGroupMetaComponent extends React.Component<
  WithTranslation & PendingGroupMetaProps & WithCurrentUserProps
> {
  render() {
    const { currentUser, group, t, groupUrl } = this.props;
    const { name } = memberWrapper(group.createdBy!);
    const anonymous = t('groups-web.member.anonymous');
    const authorName = name!.nick || anonymous;

    const couldUserResolvePendingGroups = canResolvePendingGroups(currentUser);
    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <GroupTitleLink title={group.details!.title} groupUrl={groupUrl} />
        </div>
        <p className={classes.details}>
          {couldUserResolvePendingGroups ? (
            <>
              <GroupPrivacyLevel
                t={t}
                privacyLevel={group.settings!.privacyLevel!}
              />
              &nbsp;&middot;&nbsp;
              <span
                data-hook={DATA_HOOKS.createdBy}
                data-author-name={authorName}
              >
                {t('groups-web.group-list.pending-groups.created-by', {
                  authorName,
                })}
              </span>
            </>
          ) : (
            t('groups-web.group-list.pending-groups.status-for-creator')
          )}
        </p>
      </div>
    );
  }
}

const enhance = compose(withTranslation(), withCurrentUser);

export const PendingGroupMeta = enhance(
  PendingGroupMetaComponent,
) as React.ComponentType<PendingGroupMetaProps>;
